<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template #colgroup>
        <colgroup>
          <col class="w-0">
          <col class="w-0">
          <col class="w-auto">
        </colgroup>
      </template>
      <template #item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ monthToString(item.month) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template #item-Gebruikte_code="{ item }">
        <small v-if="!item.promotie_code" class="text-green-500">Zonder promotie code</small>
        <div v-else>
          {{ item.promotie_code }}
        </div>
      </template>
      <template #item-Aantal="{ item }">
        <div>
          {{ item.aantal }}
        </div>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { monthToString } from '@/functions/formatDate'
import useGetApi from '@/hooks/useGetApi'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
})

const headers = [
  'Datum',

  'Gebruikte_code', // domein
  'Aantal', // count
]

const { data } = useGetApi('/api/dashboard/stats/ritten/promoties', props.filters, { watch: true })
</script>
